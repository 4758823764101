/**
 * All Drimify Tokens.
 *
 * This is the only place where you should list Drimiify Tokens in Javascript.
 *
 * Utility methods generate the Typescript Interface from this list.
 */
export const DrimifyCustomTokenKeys = {
  /**
   * Represents the CSS color value used to highlight a specific user in the leaderboard.
   * This color is applied to cells associated with the current user.
   */
  '--ds-leaderboard-cell-user-highlight': undefined,

  /**
   * Represents the CSS color value used to indicate changes within a leaderboard cell.
   * This color is applied to highlight modifications or updates.
   */
  '--ds-leaderboard-cell-change-highlight': undefined,

  /**
   * Represents the CSS color value used for the border of a dynamic path node.
   * This value determines the border color of nodes in a dynamic path visualization.
   */
  '--ds-dynamic-path-node-border': undefined,

  /**
   * Represents the CSS color value used for SVG lines in a dynamic path visualization.
   * This value determines the color of the connecting lines.
   */
  '--ds-dynamic-path-svg-line': undefined,

  /**
   * Represents the CSS border-radius value applied to elements.
   * This defines the curvature of corners for elements that use this variable.
   */
  '--ds-radius': undefined,

  /**
   * Represents the CSS alignment value for large headlines.
   * This value determines the text alignment (e.g., left, center, right) of large headlines.
   */
  '--ds-headline-large-align': undefined,

  /**
   * Represents the CSS color value for large headlines.
   * This value determines the text color of large headlines.
   */
  '--ds-headline-large-color': undefined,

  /**
   * Represents the CSS alignment value for medium-sized body text.
   * This value determines the text alignment (e.g., left, center, right) for medium-sized body content.
   */
  '--ds-body-medium-align': undefined,

  /**
   * Represents the CSS color value for medium-sized body text.
   * This value determines the text color of medium-sized body content.
   */
  '--ds-body-medium-color': undefined,

  /**
   * Represents the CSS color value used for text in plugins and external components.
   * This value determines the primary text color for these components.
   */
  '--ds-display-small-color': undefined,

  /**
   * Represents the CSS color value used for the outline (stroke) of text in plugins
   * and external components.
   */
  '--ds-display-small-stroke-color': undefined,

  /**
   * Represents the CSS color value used for button labels.
   * This value determines the text color for button labels.
   */
  '--ds-button-label-color': undefined,

  /**
   * Represents the CSS font-weight value for button labels.
   * This value determines the font weight (e.g., bold, normal) of button labels.
   */
  '--ds-button-label-weight': undefined,

  /**
   * Represents the CSS URL value for button textures.
   * This value specifies the URL of the texture or background image used for buttons.
   */
  '--ds-button-texture-url': undefined,
} as const;

////////////////////////////////////////////////////////////////////////////////////////
// DO NOT MODIFY BELOW THIS LINE
////////////////////////////////////////////////////////////////////////////////////////

/**
 * Generates Drimify Tokens.
 */
export const GenerateDrimifyCustomTokens = (custom?: DrimifyCustomTokens) => {
  return custom || undefined;
};

/**
 * Helper typescript syntax for mapping keys from a constant to a type.
 */
type MappedInterface<T> = {
  [K in keyof T]?: string;
};

/**
 * Generated Interface for the Drimify Tokens.
 */
export interface DrimifyCustomTokens extends Partial<MappedInterface<typeof DrimifyCustomTokenKeys>> {}
